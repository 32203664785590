var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wizard-body w-[75%] flex-shrink-0 flex-grow-0 max-w-[75%]"},[_c('empty-state',{attrs:{"title":_vm.$t('INBOX_MGMT.FINISH.TITLE'),"message":_vm.message,"button-text":_vm.$t('INBOX_MGMT.FINISH.BUTTON_TEXT')}},[_c('div',{staticClass:"w-full text-center"},[_c('div',{staticClass:"my-4 mx-auto max-w-[70%]"},[(_vm.currentInbox.web_widget_script)?_c('woot-code',{attrs:{"script":_vm.currentInbox.web_widget_script}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"w-[50%] max-w-[50%] ml-[25%]"},[(_vm.isATwilioInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),(_vm.isWhatsAppCloudInbox)?_c('div',{staticClass:"w-[50%] max-w-[50%] ml-[25%]"},[_c('p',{staticClass:"text-slate-700 dark:text-slate-200 font-medium mt-8"},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_URL'))+"\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}),_vm._v(" "),_c('p',{staticClass:"text-slate-700 dark:text-slate-200 font-medium mt-8"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'INBOX_MGMT.ADD.WHATSAPP.API_CALLBACK.WEBHOOK_VERIFICATION_TOKEN'
            ))+"\n        ")]),_vm._v(" "),_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.provider_config.webhook_verify_token}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-[50%] max-w-[50%] ml-[25%]"},[(_vm.isALineInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"w-[50%] max-w-[50%] ml-[25%]"},[(_vm.isASmsInbox)?_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.callback_webhook_url}}):_vm._e()],1),_vm._v(" "),(_vm.isAEmailInbox && !_vm.currentInbox.provider)?_c('div',{staticClass:"w-[50%] max-w-[50%] ml-[25%]"},[_c('woot-code',{attrs:{"lang":"html","script":_vm.currentInbox.forward_to_email}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex justify-center gap-2 mt-4"},[_c('router-link',{staticClass:"button hollow primary",attrs:{"to":{
            name: 'settings_inbox_show',
            params: { inboxId: this.$route.params.inbox_id },
          }}},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.FINISH.MORE_SETTINGS'))+"\n        ")]),_vm._v(" "),_c('router-link',{staticClass:"button success",attrs:{"to":{
            name: 'inbox_dashboard',
            params: { inboxId: this.$route.params.inbox_id },
          }}},[_vm._v("\n          "+_vm._s(_vm.$t('INBOX_MGMT.FINISH.BUTTON_TEXT'))+"\n        ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }